import { put } from '~/app/client'
import { getCookie } from '~/app/cookie'

// state
const state = {
  gdprConsent: getCookie('gdpr_consent'),
  subscriptions: getCookie('subscriptions'),
  ...window.user
}

// actions
const actions = {
  gdprConsent({commit}) {
    return put('/account/gdpr')
      .then((response) => commit('SET_GDPR_CONSENT', response))
  }
}

// mutations
const mutations = {
  SET_GDPR_CONSENT(state, gdprConsent) {
    state.gdprConsent = gdprConsent
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
